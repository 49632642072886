<template>
  <layout :loading="loading">
    <template v-slot:content>
      <t-alert class="my-2" :variant="alert.type" :show="!!alert.message">
        {{ alert.message }}
      </t-alert>
      <div
        class="
          flex
          justify-between
          items-center
          mt-5
          mb-4
          border-gray-300 border-b
        "
      >
        <h1 class="text-xl mt-3 mb-5">
          {{ $t("requested_holidays") }}
        </h1>
        <t-button
          class="text-sm rounded-md mr-2"
          variant="primary"
          @click="
            resetForm();
            modal = true;
          "
        >
          {{ $t("add_time_out") }}
        </t-button>
      </div>
      <div class="overflow-x-auto">
        <t-table class="app-table" :headers="headers" :data="userHolidays">
          <template slot="row" slot-scope="props">
            <tr>
              <td :class="props.tdClass">{{ props.row.from }}</td>
              <td :class="props.tdClass">{{ props.row.until }}</td>
              <td :class="props.tdClass">
                <div
                  :class="
                    props.row.approved ? 'text-green-400' : 'text-red-400'
                  "
                >
                  {{
                    $t(
                      props.row.approved
                        ? "holiday_approved"
                        : "holiday_on_hold"
                    )
                  }}
                </div>
              </td>
              <td class="w-1/3" :class="props.tdClass">
                {{ props.row.comment }}
              </td>
              <td class="w-24" :class="props.tdClass">
                <template>
                  <div
                    class="
                      grid grid-flow-col
                      auto-cols-max
                      gap-4
                      justify-center
                    "
                  >
                    <t-button
                      v-if="!isFullTimer && isEditable(props.row)"
                      class="text-sm rounded-md"
                      @click="edit(props.row)"
                    >
                      {{ $t("update") }}
                    </t-button>
                    <t-button
                      v-if="isTodayOrGreater(props.row.until)"
                      variant="danger"
                      class="text-sm rounded-md"
                      @click="removeExistingUserHoliday(props.row.id)"
                    >
                      {{ $t("edit_remove") }}
                    </t-button>
                  </div>
                </template>
              </td>
            </tr>
          </template>
        </t-table>
      </div>
      <t-modal v-model="modal">
        <form @submit.prevent="submit">
          <div class="border-gray-300 border-b py-1">
            <div class="flex flex-nowrap mb-3">
              <div class="px-2">
                <label>{{ $t("from") }}</label>
                <t-datepicker
                  v-model="form.from"
                  :variant="error.from ? 'danger' : ''"
                />
              </div>
              <div class="px-2">
                <label>{{ $t("until") }}</label>
                <t-datepicker
                  v-model="form.until"
                  :variant="error.until ? 'danger' : ''"
                />
              </div>
            </div>
            <div class="mb-3 px-2">
              <label>{{ $t("comment") }}</label>
              <t-textarea
                class="w-full"
                rows="3"
                v-model="form.comment"
                :variant="error.comment ? 'danger' : ''"
              />
            </div>
          </div>

          <div class="flex justify-end mt-3 px-2">
            <t-button
              class="text-sm"
              variant="primary"
              type="submit"
              :disabled="loading"
            >
              {{ $t("edit_save") }}
            </t-button>
          </div>
        </form>
      </t-modal>
    </template>
  </layout>
</template>

<script>
import Layout from "@/components/Layout.vue";
import { actions, getters } from "@/constants/state";
import { getMonthTranslation } from "@/utils/misc";
import { get, pick } from "lodash";
import moment from "moment";
import { mapActions, mapGetters } from "vuex";

export default {
  components: { Layout },
  data: () => ({
    id: null,
    loading: false,
    modal: false,
    form: {
      from: moment().format("YYYY-MM-DD"),
      until: moment().format("YYYY-MM-DD"),
      comment: null,
    },
    error: {
      from: false,
      until: false,
      comment: false,
    },
    alert: {
      type: "info",
      message: null,
    },
  }),
  methods: {
    ...mapActions({
      createUserHoliday: actions.HOLIDAYS_CREATE_USER_HOLIDAY_ACTION,
      updateUserHoliday: actions.HOLIDAYS_UPDATE_USER_HOLIDAY_ACTION,
      removeUserHoliday: actions.HOLIDAYS_REMOVE_USER_HOLIDAY_ACTION,
    }),
    async removeExistingUserHoliday(holidayId) {
      const { isOk } = await this.$dialog.confirm(
        "Remove timeout",
        `Are you sure?`,
        "question"
      );

      if (!isOk) {
        return;
      }

      this.loading = true;
      try {
        await this.removeUserHoliday({ id: holidayId, sendMail: 0 });
      } catch (error) {
        console.error("DEBUG: removeExistingUserHoliday", error);
      }
      this.loading = false;
    },
    async edit(holiday) {
      this.form = pick(holiday, ["from", "until", "comment"]);
      this.id = get(holiday, "id");
      this.modal = true;
    },
    async submit() {
      this.error.from = !this.form.from;
      this.error.until = !this.form.until;
      this.error.comment = !this.form.comment;

      if (!Object.keys(this.error).every((k) => !this.error[k])) {
        return;
      }

      this.loading = true;

      try {
        if (this.id) {
          await this.updateUserHoliday({
            id: this.id,
            payload: this.form,
          });
        } else {
          await this.createUserHoliday({
            ...this.form,
            user_id: this.userId,
            horeca_location_id: this.locationId,
            approved: this.isFullTimer ? false : true,
          });
        }
      } catch (error) {
        if (error.status === 400 && get(error, "data.errors.0")) {
          this.alert = {
            type: "danger",
            message: this.$i18n.t(get(error, "data.errors.0")),
          };
        }
        console.error("DEBUG: submit", error);
      }

      this.id = null;
      this.modal = false;
      this.loading = false;
    },
    resetForm() {
      this.id = null;
      this.form = {
        from: moment().format("YYYY-MM-DD"),
        until: moment().format("YYYY-MM-DD"),
        comment: null,
      };
    },
    isTodayOrGreater(date) {
      return moment(date).isSameOrAfter(moment(), "day");
    },

    isEditable(holiday) {
      const today = moment().startOf("day");
      const ongoing = today.isBetween(
        moment(holiday.from),
        moment(holiday.until),
        "day",
        "[]"
      );
      const isFuture =
        moment(holiday.from).isAfter(today) &&
        moment(holiday.until).isAfter(today);

      return ongoing || isFuture;
    },
  },
  computed: {
    ...mapGetters({
      locale: getters.SESSION_LOCALE_GETTER,
      userHolidays: getters.HOLIDAYS_USER_HOLIDAYS_GETTER,
      userId: getters.AUTH_USER_ID_GETTER,
      isFullTimer: getters.AUTH_IS_FULLTIMER_GETTER,
      locationId: getters.DOMAIN_LOCATION_ID_GETTER,
    }),
    days() {
      return Array.from(Array(31).keys(), (_, i) => `${i + 1}`.padStart(2, 0));
    },
    months() {
      return Array.from(Array(12).keys(), (_, i) => getMonthTranslation(i + 1));
    },
    years() {
      return Array.from(Array(6).keys(), (_, i) => moment().year() + i);
    },
    headers() {
      return [
        {
          value: "from",
          text: this.$i18n.t("from"),
        },
        {
          value: "until",
          text: this.$i18n.t("until"),
        },
        {
          value: "status",
          text: this.$i18n.t("status"),
        },
        {
          value: "comment",
          text: this.$i18n.t("comment"),
          width: "40%",
        },
        {},
      ];
    },
  },
  watch: {
    form: {
      handler() {
        Object.keys(this.error).forEach((key) => {
          this.error[key] = false;
        });
      },
      deep: true,
    },
  },
  filters: {
    date(value) {
      return moment(value).format("YYYY-MM-DD");
    },
  },
};
</script>

<style lang="scss" scoped></style>
